#stack {
    .container {
        padding: 6rem 0;

        img {
            margin: 0 3rem;

            @include respond(phone){
                margin: 2rem 3rem;
            }

        }
        .image-size-100 {
            width: 100;
        }
        .image-size-200 {
            width: 200;
        }
        .image-size-150 {
            width: 150;
        }


        @include respond(phone){
            padding: 0;

            .image-size-100 {
                width: 50;
            }
            .image-size-200 {
                width: 100;
            }
            .image-size-150 {
                width: 80;
            }
        }
       
    }

    

}